import type { ComponentInternalInstance } from 'vue'

type EmitsOptions = Record<string, (...args: any[]) => void>

export const createEmitsOptions = (
  instance: ComponentInternalInstance | null,
): EmitsOptions => {
  if (!instance) return {}

  const emitsOptions = (instance as Record<string, any>)?.emitsOptions || {}
  const emit = instance.emit

  return Object.keys(emitsOptions).reduce((acc, key) => {
    acc[key] = (...args: any[]) => emit(key, ...args)
    return acc
  }, {} as EmitsOptions)
}
