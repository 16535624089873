import {
  resolveRealPath,
  useManagerFormValue,
  useCurrentIdPathMap,
  type INode,
} from '@manager'
import { type Expression, Parser } from 'expr-eval'
import { parseValue } from '@manager/utils/diffManagerForm/utils'

export const useDependentOn = (node: INode, parentPath: string | undefined) => {
  const idPathMap = useCurrentIdPathMap()
  let paths = node.dependentOn
    ? node.dependentOn
        .map(({ id }) => {
          return idPathMap.get(id)
        })
        .filter((path): path is string => Boolean(path))
    : undefined

  // The `objectNodePath` will only be defined if the path points to
  // a node of kind OBJECT. We resolve the path to the array,
  // so we can evaluate the array length later.
  let objectNodePaths = paths
    ? paths.map((path) => removeLastArrayAccess(path))
    : undefined

  const isVisible = ref(!paths || paths.length === 0)
  const onVisible = (fn: Function) => {
    if (!paths || paths.length === 0) return
    watch(isVisible, (value) => {
      if (value) nextTick().then(() => fn())
    })
  }
  const onHidden = (fn: Function) => {
    if (!paths || paths.length === 0) return
    watch(isVisible, (value) => {
      if (!value) nextTick().then(() => fn())
    })
  }

  // It's always visible if it doesn't depend on anything
  if (!paths || paths.length === 0) return { isVisible, onVisible, onHidden }

  const parser = new Parser()
  const expressions = node.dependentOn!.map(({ condition }) => {
    try {
      return parser.parse(condition ?? '$isDefined($value)')
    } catch (e) {
      console.error(e)
      return null
    }
  })

  // TODO: Report to Sentry
  // Resolve the indexes of the arrays in the path if any
  if (parentPath) {
    try {
      paths = paths.map((path) => resolveRealPath(path, parentPath))
      objectNodePaths = objectNodePaths
        ? objectNodePaths.map((objectNodePath) =>
            resolveRealPath(objectNodePath, parentPath),
          )
        : undefined
    } catch (error) {
      console.error(error)
    }
  }

  // Watch for changes in the dependentOn field and update the visibility
  const { getProperty } = useManagerFormValue()
  watch(
    () => {
      return paths.map((path, index) => {
        const value = getProperty(path!)
        const objectNodeValue = (
          objectNodePaths?.[index]
            ? getProperty(objectNodePaths[index])
            : undefined
        ) as unknown[]
        return evaluate(index, value, objectNodeValue) as boolean
      })
    },
    (values) => {
      isVisible.value = values.every((value) => !!value)
    },
    { immediate: true },
  )

  return { isVisible, onVisible, onHidden }

  function evaluate(
    index: number,
    value: unknown,
    objectNodeValue?: unknown[],
  ) {
    try {
      const evaluated = expressions[index].evaluate({
        $value: value,
        $node: node,
        $length: objectNodeValue?.length, // only available for nodes of kind OBJECT
        $isDefined: (value) => !!parseValue(value),
        $hasSomeValue: (array: any, key, value) =>
          Array.isArray(array) &&
          array.some((item) => {
            return (key as string) in item && item[key as string] === value
          }),
      })
      return evaluated ?? false
    } catch (e) {
      return false
    }
  }
}

function removeLastArrayAccess(str: string) {
  const matches = [...str.matchAll(/\[\d*]$/g)]
  if (matches.length > 0) {
    // Get the last match
    const lastMatch = matches[matches.length - 1]
    // Remove the last match
    return (
      str.substring(0, lastMatch.index) +
      str.substring(lastMatch.index + lastMatch[0].length)
    )
  }
  // No matches found, return the original string
  return str
}
