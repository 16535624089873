<script setup lang="ts">
import {
  createEmitsOptions,
  getNodeDynamicComponentConfig,
  useDependentOn,
  useDynamicComponent,
} from '@manager'
import type { IManagerNodeEmits, IManagerNodeProps } from './types'

const props = defineProps<IManagerNodeProps>()
const emit = defineEmits<IManagerNodeEmits>()
const instance = getCurrentInstance()

const emitsOptions = createEmitsOptions(instance)

// Async Component
const config = getNodeDynamicComponentConfig(props.node)
const ManagerNodeComponent = useDynamicComponent(config)

// The Node don't manage the value
const parentValue = useVModel(props, 'parentValue', emit)

const { isVisible } = useDependentOn(props.node, props.parentPath)
</script>

<template>
  <!-- eslint-disable vue/attributes-order -->
  <KeepAlive v-if="node.isEnabled">
    <component
      v-if="isVisible"
      :is="ManagerNodeComponent"
      v-bind="props"
      v-on="emitsOptions"
      v-model:parent-value="parentValue"
    />
  </KeepAlive>
  <!-- eslint-enable vue/attributes-order -->
</template>
