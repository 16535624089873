export const isDate = (d: unknown): d is Date => d instanceof Date

export const isEmpty = (o: object): boolean => Object.keys(o).length === 0

export const isObject = (o: unknown): o is object =>
  o != null && typeof o === 'object'

export const hasOwnProperty = <T extends object, K extends PropertyKey>(
  o: T,
  v: K,
): o is T & Record<K, unknown> => Object.prototype.hasOwnProperty.call(o, v)

export const isEmptyObject = (o: unknown): boolean => isObject(o) && isEmpty(o)

export const makeObjectWithoutPrototype = (): Record<PropertyKey, unknown> =>
  Object.create(null)

export const hasEmptyData = (o: unknown) =>
  isObject(o) &&
  hasOwnProperty(o, 'data') &&
  Array.isArray(o.data) &&
  o.data.length === 0

export const parseValue = (v: unknown): unknown => {
  if (v === '' || v === undefined || v === null) return null
  if (v === 'True') return true
  if (v === 'False') return false
  return v
}
