import { joinPath, resolveComponentFile } from './'

export const resolveCustomComponentPath = (
  directory: string,
  ...paths: string[]
): [string, string, string] => {
  const updatedPaths = joinPath(...paths)
    .split('/')
    .map((path) => (path && !isPascalCase(path) ? pascal(path) : path))
  return [
    // CustomComponent.vue
    resolveComponentFile(joinPath(directory, ...updatedPaths)),
    // CustomComponent/index.vue
    joinPath(directory, ...updatedPaths, 'index.vue'),
    // CustomComponent/CustomComponent.vue
    resolveComponentFile(
      joinPath(
        directory,
        ...updatedPaths,
        updatedPaths[updatedPaths.length - 1],
      ),
    ),
  ]
}

function isPascalCase(str: string): boolean {
  const pascalCaseRegex = /^[A-Z][a-zA-Z]*$/
  return pascalCaseRegex.test(str)
}
