import type { IIdPathMap } from '@manager'

export const useCurrentIdPathMap = (): IIdPathMap => {
  const fieldGroupId = getCurrentFieldGroupId()
  const { idPathByPage } = storeToRefs(useManagerStore())

  const idPathMap = idPathByPage.value.get(fieldGroupId)

  if (!idPathMap) {
    throw new Error('Id path map not found')
  }

  return idPathMap
}
