import { joinPath, resolveComponentFile } from './'

export const resolveDefaultComponentPath = (
  directory: string,
  defaultComponent: string,
): [string, string, string] => {
  return [
    // CustomComponent.vue
    resolveComponentFile(joinPath(directory, defaultComponent)),
    // CustomComponent/index.vue
    joinPath(directory, defaultComponent, 'index.vue'),
    // CustomComponent/CustomComponent.vue
    resolveComponentFile(
      joinPath(directory, defaultComponent, defaultComponent),
    ),
  ]
}
