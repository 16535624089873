import type { AsyncComponentLoader } from 'vue'
import {
  resolveCustomComponentPath,
  resolveDefaultComponentPath,
} from './utils'

export interface UseDynamicComponentOptions {
  glob: () => ComponentsPaths
  directory: string
  path: string[]
  defaultComponent: string
}

export type ComponentsPaths = Record<string, AsyncComponentLoader<Component>>

const _cache = new Map<string, ComponentsPaths>()

export const useDynamicComponent = (
  options: UseDynamicComponentOptions,
): Component => {
  const { glob, directory, path, defaultComponent } = options

  if (!_cache.has(directory)) _cache.set(directory, glob())

  const componentsPaths: ComponentsPaths = _cache.get(directory)!
  const customComponentPath = resolveCustomComponentPath(directory, ...path)
  const defaultComponentPath = resolveDefaultComponentPath(
    directory,
    defaultComponent,
  )

  if (!hasComponent(defaultComponentPath)) {
    throw new Error(
      `Default component ${defaultComponentPath[0]} doesn't exist`,
    )
  }

  return defineAsyncComponent(() => {
    return (
      getComponent(customComponentPath) ?? getComponent(defaultComponentPath)!
    )
  })

  function getComponent([componentFile, componentDirIndex, componentDirFile]: [
    string,
    string,
    string,
  ]) {
    if (componentFile in componentsPaths)
      return componentsPaths[componentFile]()

    if (componentDirIndex in componentsPaths)
      return componentsPaths[componentDirIndex]()

    if (componentDirFile in componentsPaths)
      return componentsPaths[componentDirFile]()
  }

  function hasComponent([componentFile, componentDirIndex, componentDirFile]: [
    string,
    string,
    string,
  ]) {
    return (
      componentFile in componentsPaths ||
      componentDirIndex in componentsPaths ||
      componentDirFile in componentsPaths
    )
  }
}
