import { type INode, NodeDynamicComponentConfig } from '@manager'

export const getNodeDynamicComponentConfig = (node: INode) => {
  const { glob, directory, path, defaultComponent } =
    NodeDynamicComponentConfig[node.kind]

  return {
    glob,
    directory,
    path: path(node),
    defaultComponent: defaultComponent(node),
  }
}
